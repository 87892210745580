import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import GaleryView from '../views/GaleryView.vue'
import BabyView from '../views/BabyView.vue'
import BabyBauchView from '../views/BabyBauchView.vue'
import ContactView from '../views/ContactView.vue'
import BabyDetailView from '../views/BabyDetailView.vue'
import HochzeitView from '../views/HochzeitView.vue'
import ImpressumView from '../views/ImpressumView.vue'
import FamilyView from '../views/FamilyView.vue'
// import AdminView from '../views/AdminView.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/galery',
    name: 'galery',
    component: GaleryView
  },
  {
    path: '/babys',
    name: 'babys',
    component: BabyView
  },
  {
    path:'/babybauch',
    name:'babybauch',
    component: BabyBauchView
  },
  {
    path:'/contact',
    name:'contact',
    component: ContactView
  },
  {
    path:'/babydetail/:id',
    name:'babydetail',
    component: BabyDetailView,
    props:true
  },
  {
    path:'/hochzeit',
    name:'hochzeit',
    component: HochzeitView
  },
  {
    path:'/impressum',
    name:'impressum',
    component: ImpressumView
  },
  {
    path:'/familie',
    name:'familie',
    component: FamilyView
  },
  {
    path: '*',
    redirect: '/'
  }
  // {
  //   path: '/admin',
  //   name: 'admin',
  //   component: AdminView
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
